import React from "react"
import Reactmarkdown from "react-markdown"

const Texto = ({ input }) => (
  <div className="MD-Render">
    <Reactmarkdown>{input.texto}</Reactmarkdown>
    <hr />
  </div>
)

export default Texto
