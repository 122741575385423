import React from "react"
// import Img from "react-cloudinary-lazy-image"

const Autores = ({ input }) => {
  const Autor = input.autores.map(autores => (
    <div className="autor">
      <h6>{autores.nombre}</h6>
      {/* <Img
        cloudName={"lagexpress"}
        imageName={autores.foto ? autores.foto.provider_metadata.public_id : ""}
        alt={autores.foto ? autores.foto.alternativeText : ""}
        fixed={{
          width: 100,
          height: 100,
        }}
        urlParams={"r_max,q_100"}
        style={{
          width: "auto",
          height: "auto",
          display: autores.foto ? "initial" : "none",
        }}
      /> */}
      <img
        data-sizes="auto"
        className="lazyload blur-up"
        src={`https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_10,r_25,w_20,h_20/${
          autores.foto ? autores.foto.provider_metadata.public_id : ""
        }`}
        data-src={`https://res.cloudinary.com/lagexpress/image/upload/f_auto,q_auto,r_25,w_100,h_100/${
          autores.foto ? autores.foto.provider_metadata.public_id : ""
        }`}
        alt={autores.foto ? autores.foto.alternativeText : ""}
        style={{
          width: "auto",
          height: "auto",
          display: autores.foto ? "initial" : "none",
        }}
      />
    </div>
  ))

  return <div className="autores">{Autor}</div>
}

// alt={input.autores.foto ? input.autores.foto.alternativeText : ""

export default Autores
