import React from "react"
// import Img from "react-cloudinary-lazy-image"

const Foto = ({ input }) => (
  <div className="Foto">
    {/* <Img
      cloudName={"lagexpress"}
      imageName={input.foto ? input.foto.provider_metadata.public_id : ""}
      alt={input.foto ? input.foto.alternativeText : ""}
      fluid={{
        maxWidth: "auto",
      }}
      urlParams={"r_25"}
      style={{
        width: "auto",
        height: "auto",
      }}
    /> */}
    <img
      data-sizes="auto"
      className="lazyload blur-up"
      src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${
        input.foto ? input.foto.provider_metadata.public_id : ""
      }`}
      data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/${
        input.foto ? input.foto.provider_metadata.public_id : ""
      }`}
      alt={input.foto ? input.foto.alternativeText : ""}
      style={{
        display: "block",
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        margin: "auto",
      }}
    />
  </div>
)

export default Foto
