import React from "react"
import { Texto, Autores, FotoL, Foto, Galeria } from "./DynZones"

const DynZone = ({ components }) => {
  const Components = {
    // __typename: componentName,
    SP_ComponentComunesTexto: Texto,
    SP_ComponentComunesAutores: Autores,
    SP_ComponentComunesFotoIzquierda: FotoL,
    SP_ComponentComunesFoto: Foto,
    SP_ComponentComunesGaleria: Galeria,
  }

  return components.map(c => {
    const DynZoneC = Components[c.__typename]
    if (DynZoneC) {
      return <DynZoneC input={c} key={c.id} />
    }
    return null
  })
}
export default DynZone
