import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/pages/fotos.scss"
import DynZone from "../components/DynZone"

const Fotos = ({ data }) => {
  // const galeria = data.strapi.foto.galeria.map(x => (
  //   <img
  //     data-sizes="auto"
  //     className="lazyload blur-up"
  //     alt=""
  //     src={`https://res.cloudinary.com/lagexpress/image/upload/w_80,f_auto,q_auto/${x.provider_metadata.public_id}`}
  //     data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_1200,f_auto,q_auto/${x.provider_metadata.public_id}`}
  //     style={{
  //       width: "100%",
  //     }}
  //   />
  // ))

  const fotos = data.strapi.foto.fotos.map(x => (
    <img
      data-sizes="auto"
      className="lazyload blur-up"
      alt=""
      src={`https://res.cloudinary.com/lagexpress/image/upload/w_80,f_auto,q_auto/${x.provider_metadata.public_id}`}
      data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_1200,f_auto,q_auto/${x.provider_metadata.public_id}`}
      style={{
        width: "100%",
      }}
    />
  ))

  return (
    <Layout>
      <h1>Fotos</h1>
      <center>
        <DynZone components={data.strapi.foto.DynZone}></DynZone>
        {fotos}
        <img
          data-src="/fotos/dia-del-periodista.jpg"
          alt=""
          data-sizes="auto"
          className="lazyload blur-up"
        />
        <img
          data-src="/fotos/2.png"
          alt=""
          data-sizes="auto"
          className="lazyload blur-up"
        />
        <img
          data-src="/fotos/3.png"
          alt=""
          data-sizes="auto"
          className="lazyload blur-up"
        />
        <br />
        <embed
          src="https://eduardoceballos.com/fotos/fotos.swf"
          pluginspage="http://www.macromedia.com/go/getflashplayer"
          type="application/x-shockwave-flash"
          name="obj2"
          width="600"
          height="450"
          title=""
        />
      </center>
    </Layout>
  )
}

export const query = graphql`
  {
    strapi {
      foto {
        DynZone {
          __typename
          ... on SP_ComponentComunesGaleria {
            galeria {
              provider_metadata
            }
          }
          ... on SP_ComponentComunesTexto {
            texto
          }
          ... on SP_ComponentComunesFoto {
            foto {
              provider_metadata
            }
          }
        }
        fotos {
          provider_metadata
        }
      }
    }
  }
`

export default Fotos
