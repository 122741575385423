import React from "react"
import Reactmarkdown from "react-markdown"
// import Img from "react-cloudinary-lazy-image"

const FotoL = ({ input }) => (
  <div>
    {/* <Img
      cloudName={"lagexpress"}
      imageName={input.foto ? input.foto.provider_metadata.public_id : ""}
      alt={input.foto ? input.foto.alternativeText : ""}
      fluid={{
        maxWidth: "auto",
      }}
      urlParams={"r_25"}
      style={{
        float: "right",
        width: "auto",
        height: "auto",
        marginLeft: "10px",
      }}
    /> */}
    <img
      data-sizes="auto"
      className="lazyload blur-up"
      src={`https://res.cloudinary.com/lagexpress/image/upload/w_20,f_auto,q_auto/${input.foto.provider_metadata.public_id}`}
      data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_auto,f_auto,q_auto,r_25/${input.foto.provider_metadata.public_id}`}
      alt={input.foto ? input.foto.alternativeText : ""}
      style={{
        float: "right",
        width: "100%",
        maxWidth: "350px",
        marginLeft: "10px",
      }}
    />
    <Reactmarkdown>{input.texto5}</Reactmarkdown>
  </div>
)

export default FotoL
