import React from "react"
// import Img from "react-cloudinary-lazy-image"

const Foto = ({ input }) => {
  const galeria = input.galeria.map(x => (
    <img
      data-sizes="auto"
      className="lazyload blur-up"
      alt=""
      src={`https://res.cloudinary.com/lagexpress/image/upload/w_80,f_auto,q_auto/${x.provider_metadata.public_id}`}
      data-src={`https://res.cloudinary.com/lagexpress/image/upload/w_1200,f_auto,q_auto/${x.provider_metadata.public_id}`}
      style={{
        width: "100%",
      }}
    />
  ))
  return <>{galeria}</>
}

export default Foto
